import React, {Component} from 'react'
import dispatcher from '../../../dispatcher'
import { AppContext, AssetType } from '../../../libs/contextLib'
import './index.css'

import iconLinear from './linear.png'
import iconNested from './nested.png'



class Hierarchy extends Component {

	constructor(props) {
		super(props);
		this.traverse = this.traverse.bind(this)

	}

	componentDidMount() {
		dispatcher.addEventListener ('leg visibility', (event) => {
			// this is hacky, and assumes we have only one Hierarchy component in the App
			const h = document.querySelector ('.studio .studio-main .hierarchy');
			if (h) this.traverse (h, (div) => {
				if (div.tagName === 'DIV') {
					div.classList.remove ('selected');
					if (div.firstChild.textContent === event.name) {
						div.classList.add ('selected');
					}
				}
			});

			const legs = document.querySelectorAll ('.studio .studio-main .hierarchy .nonselectable.asset-shoe');

			switch (event.leg) {
				case 'right':
					legs[0].classList.remove ('hidden');
					legs[1].classList.add ('hidden');
					legs[2].classList.remove ('hidden');
					legs[3].classList.add ('hidden');
					break;
				case 'both':
					legs[0].classList.remove ('hidden');
					legs[1].classList.remove ('hidden');
					legs[2].classList.remove ('hidden');
					legs[3].classList.remove ('hidden');
					break;
				case 'left':
					legs[0].classList.add ('hidden');
					legs[1].classList.remove ('hidden');
					legs[2].classList.add ('hidden');
					legs[3].classList.remove ('hidden');
					break;
				default:
					/* keep webpack happy */
					break;
			}
		});

		dispatcher.addEventListener ('part visibility reset', (event) => {
			// this is hacky, and assumes we have only one Hierarchy component in the App

			const h = document.querySelector ('.studio .studio-main .hierarchy');
			if (h) this.traverse (h, (i) => {
				if (i.tagName === 'I') {
					if (i.parentElement.firstChild.textContent === event.part) {
						event.visible ? i.classList.remove ('hidden') : i.classList.add ('hidden');
					}
				}
			});
		});

		dispatcher.addEventListener ('switch hierarchy', (event) => {
			const h = document.querySelector ('.studio .studio-main .hierarchy');
			if (event.hierarchy === 'linear') {
				h.classList.add ('linear');
			} else {
				h.classList.remove ('linear');
			}
		});
	}


	traverse (div, f) {
		Array.prototype.forEach.call (div.children, (child) => {
			f (child); this.traverse (child, f);
		});
	}

	render () {
		return (
      <>
        <style>
          .studio .studio-main .hierarchy .nonselectable.asset-{['watch', 'shoe'][this.context.assetType]} {'{'}
            display: none !important;
          {'}'}
        </style>
        <div
          className="hierarchy"
          onClick={(event) => {
            event.preventDefault();
            const textContent = event.target.firstChild.textContent;
            if (/^remove/i.test(textContent)) {
              // eye icon click
              event.target.classList.toggle("hidden");

              dispatcher.dispatchEvent({
                type: "part visibility",
                part: event.target.parentElement.firstChild.textContent,
                visible: !event.target.classList.contains("hidden"),
              });

              dispatcher.dispatchEvent({
                type: "part visibility reset",
                part: event.target.parentElement.firstChild.textContent,
                visible: !event.target.classList.contains("hidden"),
              });
            } else {
              // folding and selecting
              var classList = event.target.classList;
              if (
                classList.contains("selected") ||
                classList.contains("nonselectable")
              ) {
                classList.toggle("collapsed");
              } else if (classList.contains("collapsed")) {
                classList.remove("collapsed");
              } else {
                this.traverse(event.currentTarget, (div) => {
                  div.classList.remove("selected");
                });
                classList.add("selected");

                dispatcher.dispatchEvent({
                  type: "selected",
                  name: textContent,
                });
              }
            }
          }}
        >
          <div className="nonselectable nested asset-shoe">
            Right foot
            <i className="checkbox-icon">remove_red_eye</i>
            <div className="selected">
              model_r
              <i className="checkbox-icon">remove_red_eye</i>
              <div className="terminal">
                ankle_occ_r
                <i className="checkbox-icon">remove_red_eye</i>
              </div>
              <div className="terminal">
                leg_occ_r
                <i className="checkbox-icon">remove_red_eye</i>
              </div>
              <div className="terminal">
                plane_occ_r
                <i className="checkbox-icon">remove_red_eye</i>
              </div>
            </div>
          </div>
          <div className="nonselectable nested hidden asset-shoe">
            Left foot
            <i className="checkbox-icon">remove_red_eye</i>
            <div>
              model_l
              <i className="checkbox-icon">remove_red_eye</i>
              <div className="terminal">
                ankle_occ_l
                <i className="checkbox-icon">remove_red_eye</i>
              </div>
              <div className="terminal">
                leg_occ_l
                <i className="checkbox-icon">remove_red_eye</i>
              </div>
              <div className="terminal">
                plane_occ_l
                <i className="checkbox-icon">remove_red_eye</i>
              </div>
            </div>
          </div>
          <div className="nonselectable linear asset-shoe">
            Right foot
            <i className="checkbox-icon">remove_red_eye</i>
            <div className="terminal selected">
              model_r
              <i className="checkbox-icon">remove_red_eye</i>
            </div>
            <div className="terminal">
              ankle_occ_r
              <i className="checkbox-icon">remove_red_eye</i>
            </div>
            <div className="terminal">
              leg_occ_r
              <i className="checkbox-icon">remove_red_eye</i>
            </div>
            <div className="terminal">
              plane_occ_r
              <i className="checkbox-icon">remove_red_eye</i>
            </div>
          </div>
          <div className="nonselectable linear hidden asset-shoe">
            Left foot
            <i className="checkbox-icon">remove_red_eye</i>
            <div className="terminal">
              model_l
              <i className="checkbox-icon">remove_red_eye</i>
            </div>
            <div className="terminal">
              ankle_occ_l
              <i className="checkbox-icon">remove_red_eye</i>
            </div>
            <div className="terminal">
              leg_occ_l
              <i className="checkbox-icon">remove_red_eye</i>
            </div>
            <div className="terminal">
              plane_occ_l
              <i className="checkbox-icon">remove_red_eye</i>
            </div>
          </div>

          <div className="nonselectable nested asset-watch">
            Wrist
            <i className="checkbox-icon">remove_red_eye</i>
            <div className="selected">
              model_w
              <i className="checkbox-icon">remove_red_eye</i>
              <div className="terminal">
                wrist_occ
                <i className="checkbox-icon">remove_red_eye</i>
              </div>
            </div>
          </div>
          <div className="nonselectable linear asset-watch">
            Wrist
            <i className="checkbox-icon">remove_red_eye</i>
            <div className="terminal selected">
              model_w
              <i className="checkbox-icon">remove_red_eye</i>
            </div>
            <div className="terminal">
              wrist_occ
              <i className="checkbox-icon">remove_red_eye</i>
            </div>
          </div>
        </div>
        <div className="hierarchy-buttons">
          <button
            onClick={() =>
              dispatcher.dispatchEvent({
                type: "switch hierarchy",
                hierarchy: "linear",
              })
            }
          >
            <img alt="" src={iconLinear} />
            <div>Linear</div>
          </button>
          <button
            onClick={() =>
              dispatcher.dispatchEvent({
                type: "switch hierarchy",
                hierarchy: "nested",
              })
            }
          >
            <img alt="" src={iconNested} />
            <div>Nested</div>
          </button>
        </div>
      </>
    );
	}
}

Hierarchy.contextType = AppContext

export default Hierarchy
