import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import dispatcher from '../../dispatcher';
import './index.css';

const defaultMessage = 'Please wait while your assets are being exported';

const PleaseWait = () => {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState(defaultMessage);

  useEffect(() => {
    dispatcher.addEventListener('hide pleasewait', hidePleaseWait);
    dispatcher.addEventListener('show pleasewait', showPleaseWait);

    return () => {
      dispatcher.removeEventListener('hide pleasewait', hidePleaseWait);
      dispatcher.removeEventListener('show pleasewait', showPleaseWait);
    };
  }, []);

  const hidePleaseWait = (event) => {
		setShow(false);
    setTimeout(() => {
      if (event.after) dispatcher.dispatchEvent(event.after);
    }, 2019);
  };

  const showPleaseWait = (event) => {
		setShow(true);
		setMessage(event.message || defaultMessage);
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <Modal
			show={show}
			onHide={handleClose}
			className="pleasewait"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>{message}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			<div class="loader">
				<div class="progress"></div>
			</div>
			</Modal.Body>
		</Modal>
  );
};

export default PleaseWait;