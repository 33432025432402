import React, {Component} from 'react'
import dispatcher from '../../../dispatcher'
import { AppContext, AssetType } from '../../../libs/contextLib';
import './index.css'

const handleClick = function (leg, modelName) {
	dispatcher.dispatchEvent ({ type: 'leg visibility', leg: leg, name: modelName });
};

dispatcher.addEventListener ('leg visibility', function (event) {
	// now that we import offsets.json...
	var radio = document.getElementById ({ right: 'object-radio-1', both: 'object-radio-2', left: 'object-radio-3' }[ event.leg ]);
	if (radio && !radio.checked) radio.checked = true;
});

class Radios extends Component {
	render () {
		// following https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_custom_radio
		// but use material icons for radios
		if (this.context.assetType === AssetType.WATCH) {
			return (
				<div className="radios">
					<div className="label">Wrist tracking</div>
				</div>
			)
		}
		return (
			<div className="radios">
				<div className="label">Foot tracking</div>
				<label className="container" htmlFor="object-radio-1">
					<input type="radio" id="object-radio-1" name="object-radio" defaultChecked onClick={() => handleClick('right', 'model_r')} />
					<i className="radio"></i>
					<span>Right only</span>
				</label>
				<label className="container" htmlFor="object-radio-2">
					<input type="radio" id="object-radio-2" name="object-radio" onClick={() => handleClick('both', 'model_r')} />
					<i className="radio"></i>
					<span>Both</span>
				</label>
				<label className="container" htmlFor="object-radio-3">
					<input type="radio" id="object-radio-3" name="object-radio" onClick={() => handleClick('left', 'model_l')} />
					<i className="radio"></i>
					<span>Left only</span>
				</label>
			</div>
		)
	}
}

Radios.contextType = AppContext;

export default Radios