import dispatcher from '../dispatcher'

import {
  ACESFilmicToneMapping,
  AnimationMixer,
  GridHelper,
  Group,
  LoadingManager,
  PerspectiveCamera,
  Raycaster,
  Scene,
  sRGBEncoding,
  WebGLRenderer,
} from 'three'

import { TransformControls } from 'three/examples/jsm/controls/TransformControls'
import { OrbitControls } from './controls/OrbitControls'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'

import React, { Component } from 'react'
import './index.css'

import './envmap.js'

import { initScene, replacePhongMaterials } from './init.js'
import {
  cacheFile,
  uncacheFile,
  exportScene,
  importOffsets,
  autoPositionModels,
} from './export.js'

import { AppContext, AssetType } from '../libs/contextLib'
import { loadFile } from '../utils'
import JSZip from 'jszip'

const renderer = new WebGLRenderer({
  antialias: window.location.hostname !== 'localhost',
})
renderer.setClearColor(0x4c4c4c)
renderer.setPixelRatio(window.devicePixelRatio || 1)
renderer.outputEncoding = sRGBEncoding
renderer.toneMapping = ACESFilmicToneMapping
renderer.toneMappingExposure = 0.8

renderer.domElement.ondragover = function (event) {
  event.preventDefault()
  event.dataTransfer.dropEffect = 'copy'
}
renderer.domElement.ondrop = function (event) {
  event.preventDefault()
  loadFile(event.dataTransfer.files[0], { type: 'import something' })
}

const scene = new Scene(),
  camera = new PerspectiveCamera(42, 1, 0.01, 3.0),
  render = function () {
    scene.traverse(function (object) {
      if (object.userData.mixer) object.userData.mixer.setTime(0)
    })
    updateVisibiles()
    renderer.render(scene, camera)
  },
  resize = function () {
    if (!renderer.domElement.parentElement) return
    var w = renderer.domElement.parentElement.clientWidth,
      h = renderer.domElement.parentElement.clientHeight
    camera.aspect = w / h
    camera.updateProjectionMatrix()
    renderer.setSize(w, h)
    render()
  }

dispatcher.addEventListener('exposure', function (event) {
  renderer.toneMappingExposure = event.exposure
  render()
})
  

const transformHistory = []
var transformHistoryIndex = -1
function clearHistory() {
  transformHistory.length = 0
  transformHistoryIndex = -1
}
function saveToHistory() {
  const record = {}
  const store = function (object) {
    if (object.name && object.name !== 'glb') {
      record[object.name] = {
        position: object.position.clone(),
        rotation: object.rotation.clone(),
        scale: object.scale.clone(),
      }
    }
  }
  store(footL)
  footL.children.forEach(store)
  store(footR)
  footR.children.forEach(store)
  // TODO will this work
  if (
    JSON.stringify(transformHistory[transformHistoryIndex]) !==
    JSON.stringify(record)
  ) {
    transformHistoryIndex++
    transformHistory.length = transformHistoryIndex
    transformHistory[transformHistoryIndex] = record

    console.log(
      new Date().toString().match(/\d{2}:\d{2}:\d{2}/)[0] +
        ': saving to history...'
    )
  }
}
function loadFromHistory() {
  const record = transformHistory[transformHistoryIndex]
  if (record) {
    const restore = function (object) {
      if (record[object.name]) {
        object.position.copy(record[object.name].position)
        object.rotation.copy(record[object.name].rotation)
        object.scale.copy(record[object.name].scale)
      }
    }
    restore(footL)
    footL.children.forEach(restore)
    restore(footR)
    footR.children.forEach(restore)
    render()
  }
}
dispatcher.addEventListener('undo', function () {
  if (transformHistoryIndex > 0) {
    transformHistoryIndex--
    loadFromHistory()
  } else {
    dispatcher.warn('could not undo - the first saved state is reached')
  }
})
dispatcher.addEventListener('redo', function () {
  if (transformHistoryIndex < transformHistory.length - 1) {
    transformHistoryIndex++
    loadFromHistory()
  } else {
    dispatcher.warn('could not redo - the last saved state is reached')
  }
})

const raycaster = new Raycaster()

var shouldRaycast = true
const blockRaycastAfterMouseMove = function () {
  shouldRaycast = false
}
renderer.domElement.addEventListener('mousedown', function () {
  renderer.domElement.addEventListener('mousemove', blockRaycastAfterMouseMove)
})
renderer.domElement.addEventListener('mouseup', function (event) {
  renderer.domElement.removeEventListener(
    'mousemove',
    blockRaycastAfterMouseMove
  )

  if (shouldRaycast && controls.enabled) {
    const mouse = {
      x: (event.layerX / renderer.domElement.offsetWidth) * 2 - 1,
      y: -(event.layerY / renderer.domElement.offsetHeight) * 2 + 1,
    }

    const rega = /_(l|r|w|occ)$/,
      feet = []
    if (footL.parent.visible) feet.push(footL.parent)
    if (footR.parent.visible) feet.push(footR.parent)
    if (wrist.parent.visible) feet.push(wrist.parent)

    raycaster.setFromCamera(mouse, camera)

    const intersects = raycaster.intersectObjects(feet, true)
    while (intersects.length) {
      var object = intersects.shift().object
      while (object.parent && !rega.test(object.name)) {
        object = object.parent
      }

      if (object.parent) {
        dispatcher.dispatchEvent({
          type: 'leg visibility',
          name: object.name,
          leg: footL.parent.visible
            ? footR.parent.visible
              ? 'both'
              : 'left'
            : 'right',
        })
        break
      }
    }
  }

  shouldRaycast = true
})

const transformControls = new TransformControls(camera, renderer.domElement)
scene.add(transformControls)

renderer.domElement.addEventListener('mousemove', function (event) {
  // make transformControls responsive to mouse...
  render()

  // block orbit controls if transformControls active
  if (transformControls.dragging) {
    controls.enabled = false
  }
})

const enableControls = function () {
  controls.enabled = true
}
renderer.domElement.addEventListener('mouseup', enableControls)
renderer.domElement.addEventListener('mouseleave', enableControls)

// WER keys, ctrl + Z/Y, G
document.addEventListener('keydown', function (event) {
  if (event.target.nodeName === 'INPUT') return
  switch (event.keyCode) {
    case 71:
      transformControls.setSpace(
        transformControls.space === 'local' ? 'world' : 'local'
      )
      break
    case 87:
      transformControls.setMode('translate')
      break
    case 69:
      transformControls.setMode('rotate')
      break
    case 82:
      transformControls.setMode('scale')
      break
    case 89:
      if (event.ctrlKey) dispatcher.dispatchEvent({ type: 'redo' })
      break
    case 90:
      if (event.ctrlKey) dispatcher.dispatchEvent({ type: 'undo' })
      break
    default:
      /* webpack needs this */ break
  }
  render()
})

const dispatchTransformPropertiesEvent = function () {
  const transformPropertiesEvent = { type: 'transform properties' }
  if (transformControls.object) {
    transformPropertiesEvent.position =
      transformControls.object.position.clone()
    transformPropertiesEvent.rotation = transformControls.object.rotation
      .toVector3()
      .multiplyScalar(180 / Math.PI)
    transformPropertiesEvent.scale = transformControls.object.scale.clone()

    const firstMesh = (
      transformControls.object.getObjectByName('glb') ||
      transformControls.object
    ).getObjectByProperty('type', 'Mesh')

    transformPropertiesEvent.opacity = firstMesh
      ? (firstMesh.material[0] || firstMesh.material).opacity * 100
      : 100
  }
  dispatcher.dispatchEvent(transformPropertiesEvent)
  saveToHistory()
}

var changeTimeOut = 0
transformControls.addEventListener('change', function () {
  // limit UI updates (do not spam)
  clearTimeout(changeTimeOut)
  changeTimeOut = setTimeout(dispatchTransformPropertiesEvent, 500)
  /*
	var object = transformControls.object;
	if (object) {

		// try to fix non-uniform scale
		var scale = 0;
		if ((object.scale.x < object.scale.y) && (object.scale.x < object.scale.z)) scale = object.scale.x; else
		if ((object.scale.x > object.scale.y) && (object.scale.x > object.scale.z)) scale = object.scale.x; else
		if ((object.scale.y < object.scale.x) && (object.scale.y < object.scale.z)) scale = object.scale.y; else
		if ((object.scale.y > object.scale.x) && (object.scale.y > object.scale.z)) scale = object.scale.y; else
		if ((object.scale.z < object.scale.y) && (object.scale.z < object.scale.x)) scale = object.scale.z; else
		if ((object.scale.z > object.scale.y) && (object.scale.z > object.scale.x)) scale = object.scale.z;
		if (scale <= 0) {
			scale = Math.max (object.scale.x, object.scale.y, object.scale.z);
		}
		if (scale > 0) {
			object.scale.setScalar (scale);
		}
	}
*/
})

const handleTransformEvent = function (propertyName, factor) {
  return function (event) {
    if (transformControls.object && transformControls.object.visible) {
      transformControls.object[propertyName][event.label] =
        event.value * (factor || 1)
      saveToHistory()
      render()
    }
  }
}

dispatcher.addEventListener('position', handleTransformEvent('position'))
dispatcher.addEventListener(
  'rotation',
  handleTransformEvent('rotation', Math.PI / 180)
)
dispatcher.addEventListener('scale', handleTransformEvent('scale'))

dispatcher.addEventListener('opacity', function (event) {
  const applyOpacity = function (material) {
    material.opacity = event.value / 100
    material.transparent = event.value < 100
  }
  if (transformControls.object && transformControls.object.visible) {
    ;(
      transformControls.object.getObjectByName('glb') ||
      transformControls.object
    ).traverse(function (child) {
      if (child.material) {
        if (child.material.length !== undefined) {
          child.material.forEach(applyOpacity)
        } else {
          applyOpacity(child.material)
        }
      }
    })
  }
  render()
})

const updateSelection = function (event) {
  transformControls.detach()
  const object = scene.getObjectByName(event.name)
  if (object && object.parent.visible && object.parent.parent.visible) {
    transformControls.attach(object)
  }
  render()
  dispatchTransformPropertiesEvent()
}

dispatcher.addEventListener('selected', updateSelection)

scene.add(new GridHelper(1.0, 50, 0x272727, 0x272727))
scene.add(new GridHelper(1.0, 10, 0x888888, 0x888888))

const createNamedGroup = function (name) {
  const group = new Group()
  group.name = name
  return group
}

// TODO: move this to an utility file
const footL = createNamedGroup('model_l')
const footR = createNamedGroup('model_r')
const wrist = createNamedGroup('model_w')

export function getSceneInfo() {
  return { footL, footR, wrist }
}

let assetType = AssetType.SHOE;
function updateVisibiles() {

  // check if ready
  if(footL.parent)

  switch (assetType) {
    case AssetType.WATCH:
      footL.parent.visible = false;
      footR.parent.visible = false;
      wrist.parent.visible = true;
    break;
    default:
    case AssetType.SHOE:
      footL.parent.visible = footL.parent.legVisible;
      footR.parent.visible = footR.parent.legVisible;
      wrist.parent.visible = false;
    break;
  }
}

function changeAssetType (type) {
  if (assetType !== type) {
    assetType = type; updateSelection ({ /* detach transform controls, etc */ });
  }
}

const addOrReplace = function (object, name) {
  var attach = false,
    existing = scene.getObjectByName(name),
    parent = /_(w|occ)$/i.test(name) ? wrist : ( /_r$/i.test(name) ? footR : footL )

  if (existing && /^model/i.test(name)) {
    existing = existing.getObjectByName((name = 'glb'))
  }

  if (existing) {
    parent = existing.parent

    object.position.copy(existing.position)
    object.quaternion.copy(existing.quaternion)
    object.scale.copy(existing.scale)
    object.visible = existing.visible

    parent.remove(existing)
    if (transformControls.object === existing) {
      transformControls.detach()
      attach = true
    }
  }

  parent.add(object)
  object.name = name

  if (attach) transformControls.attach(object)
}

dispatcher.addEventListener('switch hierarchy', function (event) {
  const names = [
    'ankle_occ_l',
    'ankle_occ_r',
    'leg_occ_l',
    'leg_occ_r',
    'plane_occ_l',
    'plane_occ_r',
    'wrist_occ',
  ]

  for (let name of names) {
    let parent = /occ$/i.test(name) ? wrist : ( /r$/i.test(name) ? footR : footL )
    if (event.hierarchy === 'linear') parent = parent.parent
    let object = scene.getObjectByName(name)
    if (object.parent !== parent) {
      parent.attach(object)
    }
  }

  // just it case it's needed to update internal stuff
  render()

  clearHistory()
  dispatchTransformPropertiesEvent()
})

camera.position.z = 0.5
camera.position.y = 0.31 * camera.position.z
camera.rotation.x = -0.3

window.addEventListener('resize', resize)
dispatcher.addEventListener('logger height has changed', resize)
dispatcher.addEventListener('preview width has changed', resize)

const controls = new OrbitControls(camera, renderer.domElement)
controls.minDistance = 10 * camera.near
controls.maxDistance = 0.5 * camera.far
controls.enableKeys = false

controls.addEventListener('change', render)

dispatcher.addEventListener('reset camera target', function () {
  console.log('reset camera');
  camera.position.sub(controls.target)
  controls.target.multiplyScalar(0)
  render()
})


let selectAssetType;
dispatcher.addEventListener('importing asset type', function (event) {
  if (selectAssetType) {
    selectAssetType (event.assetType);
  }
})

export class Scene3D extends Component {
  constructor(props) {
    super(props)
    this.wrapper = React.createRef()
  }

  componentDidMount() {
    this.wrapper.current.insertBefore(
      renderer.domElement,
      this.wrapper.current.firstChild
    )
    resize()
  }
  render() {
    selectAssetType = this.context.selectAssetType;
    changeAssetType (this.context.assetType);
    return (
      <div className='scene-3d' ref={this.wrapper}>
        <i
          className='undo history-icon'
          onClick={() => dispatcher.dispatchEvent({ type: 'undo' })}
        ></i>
        <i
          className='redo history-icon'
          onClick={() => dispatcher.dispatchEvent({ type: 'redo' })}
        ></i>
        <div
          className='reset-camera'
          onClick={() => 
            dispatcher.dispatchEvent({ type: 'reset camera target' })
          }
        >
          <img src="/assets/images/reset-camera.png" alt="reset camera" />
        </div>
        {(this.context.assetType === AssetType.SHOE) &&
        <button
          className='button auto-position'
          onClick={() => 
            dispatcher.dispatchEvent({ type: 'auto position' })
          }
        >
          Auto-position
        </button>
        }
      </div>
    )
  }
}

Scene3D.contextType = AppContext

dispatcher.addEventListener('environment map loaded', function (event) {
  scene.environment = event.makeFor(renderer)
})

dispatcher.addEventListener('import model', function (event) {
  var model

  const manager = new LoadingManager()
  manager.onLoad = function () {
    if (!model) {
      dispatcher.error('could not import ' + event.name)
      return
    }

    addOrReplace(model, event.model.split('.')[0])
    render()
    var tris = 0
    model.traverse(function (mesh) {
      if (mesh.geometry && mesh.geometry.attributes) {
        tris +=
          (mesh.geometry.index || mesh.geometry.attributes.position).count / 3
      }
    })

    const name =
      event.name === event.model
        ? event.name
        : event.name + ' (as ' + event.model + ')'

    // Save this information to package content info
    const packageElements = JSON.parse(window.sessionStorage.getItem('packageElements') || '{}');
    window.sessionStorage.setItem('packageElements', JSON.stringify({
      ...packageElements,
      [name]: {
        ...packageElements[name],
        tris,
      }
    }));

    dispatcher.log('loaded model: ' + name + ': ' + tris + ' triangles')
  }

  const gltfLoader = new GLTFLoader(manager);

  // DRACO
	const dracoLoader = new DRACOLoader();
	dracoLoader.setDecoderPath( '/draco/gltf/');
	dracoLoader.setDecoderConfig({ type: 'js' });
	gltfLoader.setDRACOLoader(dracoLoader);

  const modelLoader = /gl(b|tf)$/i.test(event.name)
    ? gltfLoader
    : new OBJLoader(manager)
  modelLoader.load(event.url, function (result) {
    model = result.scene || replacePhongMaterials(result)

    if (result.animations && result.animations[0]) {
      model.userData.mixer = new AnimationMixer(model)
      model.userData.mixer.clipAction(result.animations[0]).play()
    }
  })

  // avoid re-export if possible
  if (event.url.indexOf(';base64,') > -1) {
    cacheFile(event.model, event.url.split(';base64,')[1])
  }
})

dispatcher.addEventListener('delete model', function (event) {
  addOrReplace(new Group(), event.model.split('.')[0])
  render()

  uncacheFile(event.model)

  dispatcher.log('removed ' + event.model)
})

dispatcher.addEventListener('export scene', function (event) {
  exportScene(event, scene)
})

dispatcher.addEventListener('auto position', function (event) {
  autoPositionModels(scene)
})

dispatcher.addEventListener('import something', function (event) {
  if (/(hdr|jpe?g?|png)$/i.test(event.name)) {

    // hack: rename some files so that they can be imported
    if(/hdr$/i.test(event.name)) {
      event.name = 'envMap.hdr';
    }
    if(event.name === 'shoeIcon.png') {
      event.name = 'assetIcon.png';
    }

    switch (event.name) {
      case 'envMap.hdr':
      case 'envMap.jpg':
        event.type = 'environment map'
        dispatcher.dispatchEvent(event)
        break
      case 'assetIcon.png':
        event.type = 'shoe image'
        dispatcher.dispatchEvent(event)
        break
      default:
        dispatcher.error('could not guess how to import ' + event.name)
        break
    }
  } else {
    switch (event.name) {
      case 'ankle_occ_l.obj':
      case 'ankle_occ_r.obj':
      case 'leg_occ_l.obj':
      case 'leg_occ_r.obj':
      case 'plane_occ_l.glb':
      case 'plane_occ_r.glb':
      case 'ankle_occ_l.glb':
      case 'ankle_occ_r.glb':
      case 'leg_occ_l.glb':
      case 'leg_occ_r.glb':
      case 'wrist_occ.glb':
      case 'model_l.glb':
      case 'model_r.glb':
      case 'model_w.glb':
        event.type = 'import model'
        event.model = event.name
        dispatcher.dispatchEvent(event)
        break
      case 'offsets.json':
        try {
          importOffsets(JSON.parse(atob(event.url.split(';base64,')[1])), scene)
          render()
          dispatchTransformPropertiesEvent()

          dispatcher.log('loaded offsets.json')
        } catch (oopsie) {
          dispatcher.error('error reading offsets.json')
        }
        break
      default:
        if (event.name.substr(-3, 3).toLowerCase() === 'zip') {
          let packageElements = {}
          new JSZip()
            .loadAsync(event.url.split(';base64,')[1], { base64: true })
            .then(function (zip) {
              zip.forEach(function (path, zipObject) {
                // console.log('zipObject', zipObject)
                packageElements = {
                  ...packageElements,
                  [zipObject.name]: {
                    size: zipObject._data.uncompressedSize / 1024 / 1024, // Convert bytes to MB
                  }
                }
                zipObject
                  .async('blob')
                  .then(function (blob) {
                    blob.name = zipObject.name.replace(/^.+[\\/]/, '')
                    loadFile(blob, { type: 'import something' })
                  })
                  .catch(function (oopsie) {
                    dispatcher.warn(
                      'error reading ' + zipObject.name + ' from zip file'
                    )
                  })
                // Save information about the loaded package
                window.sessionStorage.setItem('packageElements', JSON.stringify(packageElements))  
              })
            })
            .catch(function (oopsie) {
              dispatcher.error('Error with auto positioning API');
            })
        } else {
          dispatcher.error('could not guess how to import ' + event.name)
        }
        break
    }
  }
})

dispatcher.addEventListener('copy transform to other foot', function () {
  if (transformControls.object) {
    var name = transformControls.object.name
    name =
      name.substr(0, name.length - 1) + (name.substr(-1) === 'r' ? 'l' : 'r')
    var part = scene.getObjectByName(name)
    if (part) {
      part.position.copy(transformControls.object.position)
      part.rotation.copy(transformControls.object.rotation)
      part.scale.copy(transformControls.object.scale)
      saveToHistory()
      render()
    }
  }
})

// load default assets

initScene(scene, footL, footR, wrist, function () {
  window.sessionStorage.setItem(
    'packageElements',
    JSON.stringify({
      'model_l.glb': {
        size: 1.6626319885253906,
        tris: 49998,
      },
      'plane_occ_l.glb': {
        size: 0.001251220703125,
        tris: 2,
      },
      'leg_occ_l.glb': {
        size: 0.033924102783203125,
        tris: 480,
      },
      'ankle_occ_l.glb': {
        size: 0.018848419189453125,
        tris: 576,
      },
      'model_r.glb': {
        size: 1.6626319885253906,
        tris: 49998,
      },
      'plane_occ_r.glb': {
        size: 0.001251220703125,
        tris: 2,
      },
      'leg_occ_r.glb': {
        size: 0.033924102783203125,
        tris: 480,
      },
      'ankle_occ_r.glb': {
        size: 0.018848419189453125,
        tris: 576,
      },
    })
  );

  transformControls.attach(footR);

  render();

  saveToHistory();

  dispatcher.addEventListener('leg visibility', function (event) {
    footL.parent.legVisible = event.leg === 'both' || event.leg === 'left';
    footR.parent.legVisible = event.leg === 'both' || event.leg === 'right';
    updateSelection(event);
  });
  dispatcher.addEventListener('part visibility', function (event) {
    var part = scene.getObjectByName(event.part);
    if (part && /^model/i.test(event.part)) {
      part = part.getObjectByName('glb');
    }
    if (part) {
      part.visible = event.visible;
    }
    if (event.part === 'Left foot')
      footL.parent.getObjectByProperty('type', 'Mesh').visible = event.visible;
    if (event.part === 'Right foot')
      footR.parent.getObjectByProperty('type', 'Mesh').visible = event.visible;
    if (event.part === 'Wrist')
      wrist.parent.getObjectByProperty('type', 'Mesh').visible = event.visible;
    render();
  });
})
