import React, { Component } from 'react'
import { Dropdown } from 'react-bootstrap'
import dispatcher from '../../../dispatcher'
import { loadFile } from '../../../utils'
import './index.css'

import shoe from './shoe.png';
import watch from './watch.png';
import uploadIcon from "./upload.svg";
import { AppContext, AssetType } from '../../../libs/contextLib';

const meta = {
  shortDescription: "",
  longDescription: "",
  image: "",
};

function updateMeta(input, propertyName) {
  meta[propertyName] = input.value;

  // highlight parent div
  const classList = input.parentElement.classList;
  if (input.value) classList.add("has-data");
  else classList.remove("has-data");
}

// hacky, but..
dispatcher.addEventListener("metainfo request", function (event) {
  Object.assign(event, meta);
});

class MetaInfo extends Component {
  constructor(props) {
    super(props);
    this.shrtDescInput = React.createRef();
    this.longDescInput = React.createRef();
    this.imageDiv = React.createRef();
  }

  loadImageData = (event) => {
    meta.image = event.url;
    this.imageDiv.current.classList.add("has-data");
    this.imageDiv.current.style.backgroundImage = "url(" + event.url + ")";
    this.imageDiv.current.querySelector('input[type="file"]').value = "";
  };

  loadDescriptions = (event) => {
    this.shrtDescInput.current.value = event.shortDescription || "";
    updateMeta(this.shrtDescInput.current, "shortDescription");

    this.longDescInput.current.value = event.longDescription || "";
    updateMeta(this.longDescInput.current, "longDescription");
  };

  componentDidMount() {
    dispatcher.addEventListener("shoe image", this.loadImageData);
    dispatcher.addEventListener("shoe descriptions", this.loadDescriptions);
  }

  componentWillUnmount() {
    dispatcher.removeEventListener("shoe image", this.loadImageData);
    dispatcher.removeEventListener("shoe descriptions", this.loadDescriptions);
  }

  render() {
    let backgroundImage;
    switch(this.context.assetType) {
      case AssetType.SHOE:
        backgroundImage = shoe;
        break;
      case AssetType.WATCH:
        backgroundImage = watch;
        break;
      default:
        // transparent pixel
        backgroundImage = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
        break;
    }

    return (
      <>
        <div className="meta-info">
          <label>Type</label>
          <Dropdown>
            <Dropdown.Toggle variant="secondary">
              {['Shoe', 'Watch'][this.context.assetType]}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => this.context.selectAssetType(AssetType.SHOE)}>Shoe</Dropdown.Item>
              <Dropdown.Item onClick={() => this.context.selectAssetType(AssetType.WATCH)}>Watch</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="meta-info">
          <label>Name</label>
          <input
            autoComplete="off"
            ref={this.shrtDescInput}
            onInput={() =>
              updateMeta(this.shrtDescInput.current, "shortDescription")
            }
          />
        </div>
        <div className="meta-info">
          <label>Description</label>
          <input
            autoComplete="off"
            ref={this.longDescInput}
            onInput={() =>
              updateMeta(this.longDescInput.current, "longDescription")
            }
          />
        </div>
        <div className="meta-info last">
          <label>Thumbnail picture</label>
          <div
            className="dropzone"
            style={{ backgroundImage: "url(" + backgroundImage + ")", height: "107px" }}
            ref={this.imageDiv}
          >
            <div className="button file">
              <div
                className="upload-icon"
                style={{
                  backgroundImage: "url(" + uploadIcon + ")",
                }}
              ></div>
              <input
                type="file"
                accept=".png"
                onChange={(event) => {
                  loadFile(
                    event.nativeEvent.target.files[0],
                    this.loadImageData
                  );
                }}
              ></input>
            </div>
          </div>
        </div>
      </>
    );
  }
}

MetaInfo.contextType = AppContext;

export default MetaInfo