import React, { useEffect, useState } from 'react';


import Logo from '../../ui/header/logo';
import NavBar from '../../ui/header/navbar';
import Logout from '../../ui/header/logout';

import MetaInfo from '../../ui/main/metainfo';
import Title from '../../ui/main/title';
import Radios from '../../ui/main/radios';
import Hierarchy from '../../ui/main/hierarchy';
import Logger from '../../ui/main/logger';
import Model from '../../ui/main/model';
import Transform from '../../ui/main/transform';

import Preloader from '../../ui/preloader';
import PleaseWait from '../../ui/pleasewait';
import UploadForm from '../../ui/uploadform';

import Tabs from "../../ui/main/tabs";

import { Scene3D } from "../../scene3d";
import { Preview3D } from "../../preview3d";

import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { useAppContext } from "../../libs/contextLib";
import Lighting from '../../ui/main/lighting';
import Dropdown from 'react-dropdown'

import dispatcher from '../../dispatcher';
import ImportExport from '../../ui/main/importexport';
import ModalApiKeys from '../../ui/main/modalapikeys';

export default function Home() {
  const [initScene, handlerInitScene] = useState(false);
  const { userHasAuthenticated } = useAppContext();
  const history = useHistory();

  const colLeftTabs = [
    {
      id: 1,
      title: 'Product',
      content: <MetaInfo />,
    },
    {
      id: 2,
      title: 'Objects',
      content: (
        <>
          <Radios />
          <Hierarchy init={initScene} />
          <Model />
        </>
      ),
    },
    {
      id: 3,
      title: 'Lighting',
      content: (
        <>
          <Lighting />
        </>
      ),
    },
  ];

  const colRightTabs = [
    {
      id: 1,
      title: "Preview",
      content: <>
        <Preview3D />
        <Transform />
      </>,
    },
  ];
  

  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(false);
    history.push("/login");
  }



  useEffect(() => {
    handlerInitScene(true);
  }, []);

  return (
    <div className="studio">
      {/* <header className="studio-header">
        <Logo />
        <NavBar />
        <Logout handleLogout={handleLogout} />
      </header> */}
      <main className="studio-main">
        <div className="col-1">
          <Tabs tabs={colLeftTabs}></Tabs>
          <div className="buttons">
            <ModalApiKeys />
            <button className="button button-logout button-right-angle" onClick={handleLogout}>
              Logout
            </button>
            
          </div>
        </div>
        <div className="col-2">
          <Scene3D init={initScene} />
          <div
            style={{
              flex: "none",
              maxHeight: "70px",
              height: "70px",
            }}
          >
            <Title icon="description" text="Logger" />
            <Logger />
          </div>
        </div>
        <div className="col-3">
          <Tabs tabs={colRightTabs}></Tabs>
          <ImportExport />
        </div>
      </main>
      <Preloader />
      <PleaseWait />
      <UploadForm />

      
    </div>
  );
}
