import React, { useCallback, useState } from 'react';
import dispatcher from '../../../dispatcher';
import './index.css';

let onExposureReset;
dispatcher.addEventListener('exposure reset', function (event) {
  if(onExposureReset) onExposureReset(event.exposure)
});

const ImageExposureAdjustment = () => {
  const [exposureValue, setExposureValue] = useState(1.0);

  const onMount = useCallback(function(mounted) {
    if(mounted) onExposureReset = setExposureValue; else onExposureReset = undefined;
  })

  const handleExposureChange = (event) => {
    const newValue = parseFloat(event.target.value);
    setExposureValue(newValue);
    dispatcher.dispatchEvent({ type: 'exposure', exposure: newValue });
  };

  return (
    <div style={{ width: '100%' }} ref={onMount}>
      <div className="label">Exposure</div>
      <div className='exposure-inputs'>
        <input
          type="range"
          min="0.1"
          max="2"
          step="0.1"
          value={exposureValue}
          onInput={handleExposureChange}
          onChange={handleExposureChange}
        />
        <input
          type="number"
          min="0.1"
          max="2"
          step="0.1"
          value={exposureValue}
          onChange={handleExposureChange}
        />
      </div>
    </div>
  );
};

export default ImageExposureAdjustment;