import React, { useState } from "react";
import Dropdown from 'react-dropdown';
import "./index.css";
import dispatcher from "../../../dispatcher";
import { exportSceneFor, loadFile } from "../../../utils";

const exportOptions = [
  { value: 'android', label: 'Android' },
  { value: 'ios2', label: 'iOS - Google Converter' },
  { value: 'ios1', label: 'iOS - Apple converter' },
  { value: 'ios3', label: 'iOS - ThreeJS' },
]

const exportDefaultOption = { value: 'default', label: 'Export' };

const ImportExport = () => {
  const [selectedOption, setSelectedOption] = useState(exportDefaultOption);
  const dropdownRef = React.createRef(null);
  const inputRef = React.createRef(null);

  const dropdownDefaultState = {
    selected: {
      value: 'default',
      label: 'Export'
    },
    isOpen: false
  }

  return (
    <div className="buttons import-export">
      <input
        ref={inputRef}
        className="input-file"
        type="file"
        accept=".drc,.glb,.gltf,.obj,.json,.zip,.png,.hdr,.jpe,.jpeg,.jpg"
        onChange={(event) => {
          loadFile(event.nativeEvent.target.files[0], {
            type: 'import something',
          });
        }}
      />
      <button className="button" onClick={() => inputRef.current.click()}>
        Import
      </button>
      {/* <button className="button" onClick={() => dispatcher.dispatchEvent({ type: 'export' }) }>Export</button> */}
      <Dropdown
        ref={dropdownRef}
        options={exportOptions}
        onChange={function (option) {
          exportSceneFor(option.value);
          setTimeout(() => {
            dropdownRef.current.setState(dropdownDefaultState);
          }, 0);
        }}
        value={selectedOption}
      />
    </div>
  );
};
export default ImportExport;
