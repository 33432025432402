import dispatcher from '../dispatcher'

import JSZip from 'jszip'

import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter'
import { USDZExporter } from './usdz'

import { getAPIUserNameAndKey, loadFile } from '../utils'


const cache = {}, addFromCache = function (name, zip) {
	const content = cache[name];
	if (content) {
		console.log ('exporting cached version of ' + name);
		if (content.byteLength !== undefined) {
			zip.file (name, content);
		} else {
			zip.file (name, content, { base64: true });
		}
		return true;
	}
};

export function cacheFile (name, content) {
	cache[name] = content; console.log ('cached ' + name + ' for export'); return content;
}

export function uncacheFile (name) {
	if (cache[name]) {
		delete cache[name]; console.log ('uncached ' + name);
	}
}


var exportHadErrors = false;


const link = document.createElement ('a');
const glbExporter = new GLTFExporter ();

const opacityHelper = function (opacity) { return function (material) { if (material) {
	if (material.forEach) {
		material.forEach (function (every) { every.opacity = opacity; });
	} else {
		material.opacity = opacity;
	}
}}};


function createHandDataStub () {
  return {
    apparel: [{
      name: 'wristwear',
      glb_uri: 'model_w.glb',
      usdz_uri: 'model_w.usdz',
      scalingFactor: 1,
    }],
    occluders: [{
      name: 'wrist',
      mask: 'wrist',
      glb_uri: 'wrist_occ.glb',
      usdz_uri: 'wrist_occ.usdz',
      scalingFactor: 1,
    }]
  }
}

function createFootDataStub (character) {
	return {
		apparel: [{
			name: 'shoe',
			glb_uri: 'model_' + character + '.glb',
			usdz_uri: 'model_' + character + '.usdz',
			scalingFactor: 1
		}],
		occluders: [{
			name: 'plane',
			mask: 'Trousers',
			glb_uri: 'plane_occ_' + character + '.glb',
			usdz_uri: 'plane_occ_' + character + '.usdz',
			scalingFactor: 1
		}, {
			name: 'leg',
			mask: 'LegAndTrousers',
			glb_uri: 'leg_occ_' + character + '.glb',
			usdz_uri: 'leg_occ_' + character + '.usdz',
			scalingFactor: 1
		}, {
			name: 'ankle',
			mask: 'FootLegAndTrousers',
			glb_uri: 'ankle_occ_' + character + '.glb',
			usdz_uri: 'ankle_occ_' + character + '.usdz',
			scalingFactor: 1
		}]
	}
}


let lastExposure = 1.0
dispatcher.addEventListener('exposure', function (event) {
  lastExposure = event.exposure
})

export function exportScene (event, scene) {
  getAPIUserNameAndKey(event?.exportType === 'android').then(function (api) {
    if (!event.callBack) {
      dispatcher.dispatchEvent({ type: 'show pleasewait' });
    }

    exportHadErrors = false;

    // map opacities and visibilities
    const map_o = {};
    scene.traverse(function (object) {
      map_o[object.uuid] = object.material
        ? (object.material[0] || object.material).opacity
        : 1;
    });

    const isWatch = scene.getObjectByName('model_w').parent.visible;

    const json = {
      assetId: '',
      organizationId: '',
      encrypted: false,
      schemaVersion: '2.0',
      type: isWatch ? 'wrist' : 'foot',
      viewerAttributes: {
        exposure: lastExposure,
        exposure_viewer: lastExposure,
        initialCameraPosition: { yaw: '0.0deg', pitch: '80.0deg', distance: 'auto' },
        shadow: { shadowIntensity: 1, shadowSoftness: 1 },
        playInbuiltAnimation: false, rotate: { autoRotate: false, rotationPerSecond: 0.1 }
      },
      WebDirectionalLight: {colour: "0xffffff", intensity: 0.0},
      WebAmbientLight: {colour: "0xffffff", intensity: 0.0},
      date_time: new Date().toISOString().replace('T', ' ').replace('Z', ''),
    };

    if(isWatch) {
      json.wrist = createHandDataStub();
    } else {
      json.footLeft = createFootDataStub('l');
      json.footRight = createFootDataStub('r');

    json.left_foot = scene.getObjectByName('model_l').parent.visible;
    json.right_foot = scene.getObjectByName('model_r').parent.visible;

    json.both_feet = json.left_foot && json.right_foot;
    if (json.both_feet) {
      json.left_foot = json.right_foot = false;
    }
    }

    // turn everything on
    const opacityTo1 = opacityHelper(1);
    scene.traverse(function (object) {
      opacityTo1(object.material);
    });

    scene.updateMatrixWorld(true);

    // start the zip file
    const zip = new JSZip();

    // export meta info
    const meta = {
      type: 'metainfo request',
    };
    dispatcher.dispatchEvent(meta);

    json.icon_uri = 'assetIcon.png';
    json.shortShoeDescription = meta.shortDescription;
    json.longShoeDescription = meta.longDescription;
    if (meta.image) {
      zip.file(json.icon_uri, meta.image.split(';base64,')[1], {
        base64: true,
      });
    } else {
      json.icon_uri = '';
    }

    // export env. map
    json.environmentMap = 'envMap.jpg';
    if (!addFromCache(json.environmentMap, zip)) {
      json.environmentMap = 'envMap.hdr';
      if (!addFromCache(json.environmentMap, zip)) {
        dispatcher.warn('could not export environment map');
        exportHadErrors = true;
      }
    }

    // scheme 2.0 counterparts
    json.asset_icon_uri = json.icon_uri;
    json.shortDescription = json.shortShoeDescription;
    json.longDescription = json.longShoeDescription;
    json.viewerAttributes.environmentImage = json.environmentMap;
    json.viewerAttributes.environmentImage_viewer = json.environmentMap;

    const ios = event.exportType.match(/^ios(\d)/);
    const usdzExporter = ios && ios[1] === '3' && new USDZExporter();

    // async export queue
    const queue = [];

    if (json.wrist)
      queue.push(
        {
          data: json.wrist.apparel[0],
          object: scene.getObjectByName('model_w'),
          glb: true
        },
        {
          data: json.wrist.occluders[0],
          object: scene.getObjectByName('wrist_occ'),
        }
      );

    if (json.left_foot || json.both_feet)
      queue.push(
        {
          data: json.footLeft.apparel[0],
          object: scene.getObjectByName('model_l'),
          glb: true,
        },
        {
          data: json.footLeft.occluders[0],
          object: scene.getObjectByName('plane_occ_l'),
        },
        {
          data: json.footLeft.occluders[1],
          object: scene.getObjectByName('leg_occ_l'),
        },
        {
          data: json.footLeft.occluders[2],
          object: scene.getObjectByName('ankle_occ_l'),
        }
      );

    if (json.right_foot || json.both_feet)
      queue.push(
        {
          data: json.footRight.apparel[0],
          object: scene.getObjectByName('model_r'),
          glb: true,
        },
        {
          data: json.footRight.occluders[0],
          object: scene.getObjectByName('plane_occ_r'),
        },
        {
          data: json.footRight.occluders[1],
          object: scene.getObjectByName('leg_occ_r'),
        },
        {
          data: json.footRight.occluders[2],
          object: scene.getObjectByName('ankle_occ_r'),
        }
      );

    const exportNextModel = function () {
      if (queue.length) {
        const next = queue.shift();
        if (next.object) {
          // get the model
          const object = next.glb
            ? next.object.getObjectByName('glb')
            : next.object;
          if (!object) {
            // the model is missing
            dispatcher.warn('nothing to export for ' + next.data.glb_uri);
            exportHadErrors = true;
            return exportNextModel();
          }

          // save the transformation, etc
          next.data.hidden = !object.visible;

          const mesh = object.getObjectByProperty('type', 'Mesh');
          next.data.opacity = mesh ? map_o[mesh.uuid] : 1;

          next.data.quaternion = [
            next.object.quaternion.x,
            next.object.quaternion.y,
            next.object.quaternion.z,
            next.object.quaternion.w,
          ];
          next.data.translation = [
            next.object.position.x,
            next.object.position.y,
            next.object.position.z,
          ];
          next.data.rotation = [
            next.object.rotation.x,
            next.object.rotation.y,
            next.object.rotation.z,
          ];
          next.data.scale = [
            next.object.scale.x,
            next.object.scale.y,
            next.object.scale.z,
          ];

          // actually export the model
          if (!usdzExporter && addFromCache(next.data.glb_uri, zip)) {
            exportNextModel();
          } else {
            // not in cache - probably loaded *.obj model
            const parent = object.parent;
            const position = object.position.clone();
            object.position.setScalar(0);
            const rotation = object.rotation.clone();
            object.rotation.set(0, 0, 0);
            const scale = object.scale.clone();
            object.scale.setScalar(1);

            object.parent = null;
            object.updateMatrixWorld(true);

            (function (fileName, object, parent, position, rotation, scale) {
              const restoreObjectProperties = function () {
                object.parent = parent;
                object.position.copy(position);
                object.rotation.copy(rotation);
                object.scale.copy(scale);
                object.updateMatrixWorld(true);
              };

              if (usdzExporter) {
                usdzExporter.parse(object).then(function (arrayBuffer) {
                  restoreObjectProperties();

                  const blob = new Blob([arrayBuffer], {
                    type: 'application/octet-stream',
                  });
                  zip.file(fileName.replace('.glb', '.usdz'), blob);

                  exportNextModel();
                });
              } else {
                glbExporter.parse(
                  object,
                  function (result) {
                    restoreObjectProperties();

                    zip.file(fileName, result);

                    exportNextModel();
                  },
                  { binary: true, onlyVisible: false }
                );
              }
            })(next.data.glb_uri, object, parent, position, rotation, scale);
          }
        } else {
          // the scene is broken
          dispatcher.error(
            'encountered a bug trying to export for ' + next.data.glb_uri
          );
          exportHadErrors = true;
          exportNextModel();
        }
      } else {
        // we are almost done
        const mirrorLeg = function (from, to) {
          if (from.apparel[0].translation && !to.apparel[0].translation) {
            to.apparel[0].translation = [
              -from.apparel[0].translation[0],
              from.apparel[0].translation[1],
              from.apparel[0].translation[2],
            ];
            to.apparel[0].scale = [
              -from.apparel[0].scale[0],
              from.apparel[0].scale[1],
              from.apparel[0].scale[2],
            ];
            to.apparel[0].rotation = [
              from.apparel[0].rotation[0],
              -from.apparel[0].rotation[1],
              -from.apparel[0].rotation[2],
            ];
            to.apparel[0].quaternion = [
              from.apparel[0].quaternion[0],
              -from.apparel[0].quaternion[1],
              -from.apparel[0].quaternion[2],
              from.apparel[0].quaternion[3],
            ];
            to.apparel[0].glb_uri = from.apparel[0].glb_uri;
            to.apparel[0].usdz_uri = from.apparel[0].usdz_uri;
            to.apparel[0].hidden = from.apparel[0].hidden;
            for (let i = 0; i < 3; i++) {
              to.occluders[i].translation =
                from.occluders[i].translation.slice();
              to.occluders[i].scale = from.occluders[i].scale.slice();
              to.occluders[i].rotation = from.occluders[i].rotation.slice();
              to.occluders[i].quaternion = from.occluders[i].quaternion.slice();
              to.occluders[i].glb_uri = from.occluders[i].glb_uri;
              to.occluders[i].usdz_uri = from.occluders[i].usdz_uri;
              to.occluders[i].hidden = from.occluders[i].hidden;
            }
          }
        };

        if(!isWatch) {
        mirrorLeg(json.footLeft, json.footRight);
        mirrorLeg(json.footRight, json.footLeft);
        }

        zip.file('offsets.json', JSON.stringify(json));

        // save the zip file
        const savePackageZip =
          event.callBack ||
          function (blob) {
            link.href = URL.createObjectURL(blob);
            link.download = 'package.zip';
            link.dispatchEvent(new MouseEvent('click'));

            const message =
              'finished exporting package.zip for ' +
              event.exportType +
              (exportHadErrors ? ' with errors' : '');

            if (exportHadErrors) {
              dispatcher.error(message);
            } else {
              dispatcher.success(message);
            }

            dispatcher.dispatchEvent({ type: 'hide pleasewait' });
          };

        zip.generateAsync({ type: 'blob' }).then(
          ios && !usdzExporter
            ? function (blob) {
                const request = new XMLHttpRequest();
                request.responseType = 'blob';
                request.onerror = function () {
                  dispatcher.error('error converting to usdz :(');
                  exportHadErrors = true;
                  savePackageZip(blob);
                };
                request.onload = function () {
                  savePackageZip(request.response);
                };
                request.open(
                  'POST',
                  'https://app' + ios[1] + '.vyking.io/cgi-bin/glb_to_usdz.cgi'
                );

                const username = api.username;
                const password = api.key;
                request.setRequestHeader(
                  'Authorization',
                  'Basic ' + btoa(username + ':' + password)
                );

                request.send(blob);
              }
            : savePackageZip
        );

        // restore opacities
        scene.traverse(function (object) {
          opacityHelper(map_o[object.uuid])(object.material);
        });
      }
    };

    exportNextModel();
  }, dispatcher.error);
}

// since the data in offsets.json is defined by exportScene, it is here for now
export function importOffsets (json, scene) {
  const assetType = json.type === 'wrist' ? 1 : 0;
  dispatcher.dispatchEvent({
    type: 'importing asset type', assetType
  });

  dispatcher.dispatchEvent({
		type: 'switch hierarchy', hierarchy: 'nested'
	});

	if (json.both_feet) {
		dispatcher.dispatchEvent ({ type: 'leg visibility', leg: 'both', name: 'model_r' });
	} else if (json.left_foot) {
		dispatcher.dispatchEvent ({ type: 'leg visibility', leg: 'left', name: 'model_l' });
	} else if (json.right_foot) {
		dispatcher.dispatchEvent ({ type: 'leg visibility', leg: 'right', name: 'model_r' });
	} else if (json.wrist) {
    dispatcher.dispatchEvent ({ type: 'leg visibility', leg: 'left', name: 'model_w' });
  }

	dispatcher.dispatchEvent ({
		type: 'shoe descriptions',
		shortDescription: json.shortShoeDescription || json.shortDescription,
		longDescription: json.longShoeDescription || json.longDescription
	});

  if(json.viewerAttributes) {
    dispatcher.dispatchEvent({ type: 'exposure', exposure: json.viewerAttributes.exposure });
    dispatcher.dispatchEvent({ type: 'exposure reset', exposure: json.viewerAttributes.exposure });
  }

	[
    { name: 'model_w', data: json.wrist?.apparel[0] },
    { name: 'wrist_occ', data: json.wrist?.occluders[0] },
		{ name: 'model_l', data: json.footLeft?.apparel[0] },
		{ name: 'plane_occ_l', data: json.footLeft?.occluders[0] },
		{ name: 'leg_occ_l', data: json.footLeft?.occluders[1] },
		{ name: 'ankle_occ_l', data: json.footLeft?.occluders[2] },
		{ name: 'model_r', data: json.footRight?.apparel[0] },
		{ name: 'plane_occ_r', data: json.footRight?.occluders[0] },
		{ name: 'leg_occ_r', data: json.footRight?.occluders[1] },
		{ name: 'ankle_occ_r', data: json.footRight?.occluders[2] }

	].forEach (function (next) {
		var name = next.name;
		var data = next.data;
		if (data && (data.glb_uri.indexOf (name) > -1)) {
			var object = scene.getObjectByName (name);
			if (object) {
				var glbOrObject = object.getObjectByName ('glb') || object;
				if (data.translation) object.position.set (data.translation[0], data.translation[1], data.translation[2]);
				if (data.rotation) object.rotation.set (data.rotation[0], data.rotation[1], data.rotation[2]);
				if (data.scale) object.scale.set (data.scale[0], data.scale[1], data.scale[2]);
				if (isFinite (data.opacity)) {
					var oh = opacityHelper (data.opacity); glbOrObject.traverse (function (child) { oh (child.material); });
				}
				if (data.hidden !== undefined) {
					glbOrObject.visible = !data.hidden;
					dispatcher.dispatchEvent ({
						type: 'part visibility reset', part: name, visible: glbOrObject.visible
					});
				}
			}
		}
	});
}

export function autoPositionModels(scene) {
	getAPIUserNameAndKey().then (function (api) {

	// TODO is this needed?
	dispatcher.dispatchEvent({
		type: 'switch hierarchy', hierarchy: 'linear'
	});

	const model_l = scene.getObjectByName ('model_l');
	const model_r = scene.getObjectByName ('model_r');

	const name_l = createFootDataStub ('l').apparel[0].glb_uri;
	const name_r = createFootDataStub ('r').apparel[0].glb_uri;

	const exporter = new GLTFExporter ();
	dispatcher.dispatchEvent ({ type: 'show pleasewait', message: 'Please wait a moment...' });

	const parseAsync = function (object) {
		return new Promise (function (resolve) {
			exporter.parse (object, resolve, { binary: true, onlyVisible: false });
		});
	};

	Promise.all([
		model_l.parent.visible ? (
			(cache[name_l] && cache[name_l].byteLength) ?
				Promise.resolve (cache[name_l]) : parseAsync (model_l)
		) : Promise.resolve (undefined),
		model_r.parent.visible ? (
			(cache[name_r] && cache[name_r].byteLength) ?
				Promise.resolve (cache[name_r]) : parseAsync (model_r)
		) : Promise.resolve (undefined)
	]).then(function (results) {
		const data = new FormData ();
		const request = new XMLHttpRequest ();
		request.responseType = 'blob';

		request.addEventListener ('load', function () {
			dispatcher.dispatchEvent ({ type: 'hide pleasewait' });

			request.response.name = 'autopose.zip';
			loadFile (request.response, { type: 'import something' });
		});

		request.addEventListener ('error', function (error) {
			console.error (error);
			dispatcher.dispatchEvent ({ type: 'hide pleasewait' });
		})

		if (results[0]) data.append ('left_glb', new Blob([results[0]]), name_l);
		if (results[1]) data.append ('right_glb', new Blob([results[1]]), name_r);

		data.append ('user_id', api.key);

		const meta = { type: 'metainfo request' };
		dispatcher.dispatchEvent (meta);

		data.append ('company', meta.shortDescription);
		data.append ('sku_id', meta.longDescription);

		request.open ('POST', 'https://footfitting.vyking.io/cgi-bin/glb_to_ar.p');
		request.send (data);
	});

	}, dispatcher.error);
}