import dispatcher from '../dispatcher'

export function autoPosition () {
	dispatcher.dispatchEvent({
		type: 'auto position'
	});
}

export function uploadForm () {
	dispatcher.dispatchEvent({
		type: 'upload form'
	});
}

export function exportSceneFor (exportType, callBack) {
	dispatcher.dispatchEvent({
		type: 'switch hierarchy', hierarchy: 'nested'
	});
	dispatcher.dispatchEvent ({
		type: 'export scene', exportType: exportType, callBack: callBack
	});
}

export function loadFile (file, event) {
	if (file) {
		const reader = new FileReader ();
		reader.addEventListener ('load', function () {
			if (typeof event === 'function') {
				event ({
					name: file.name,
					url: reader.result
				});
			} else {
				event.name = file.name;
				event.url = reader.result;
				dispatcher.dispatchEvent (event);
			}
		}, false);
		reader.readAsDataURL (file);
	}
}

export function getAPIUserNameAndKey(skip = false) {
  // Promise in case this will have to be fetched from server
  return new Promise(function (resolve, reject) {
    if (skip) return resolve({ username: null, key: null });
    const username = localStorage.getItem('api_username');
    if (!username) return reject('api username is not set');
    const key = localStorage.getItem('api_key');
    if (!key) return reject('api key is not set');
    resolve({ username, key });
  });
}

export function setAPIUserNameAndKey(username, key) {
	console.log('setAPIUserNameAndKey', username, key);
	if(username) localStorage.setItem('api_username', username);
	if(key) localStorage.setItem('api_key', key);
}