import dispatcher from '../dispatcher'

import {
	Cache,
	DoubleSide,
	LoadingManager,
	Mesh,
	MeshStandardMaterial,
	PlaneBufferGeometry,
	FileLoader,
	Group
} from 'three'

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'

import { xFlipObject3D } from './flip.js'
import { cacheFile } from './export.js'

import default_shoe_left from './assets/default_foot_model_left.glb'
import default_shoe_right from './assets/default_foot_model_right.glb'
import default_ankle_left from './assets/default_ankle_model_left.glb'
import default_ankle_right from './assets/default_ankle_model_right.glb'
import default_leg from './assets/leg_occluder.obj'
import default_foot from './assets/foot.obj'
import default_watch from './assets/default_watch_model.glb'
import default_wrist from './assets/hand_wrist_base.glb'
import default_wrist_occluder from './assets/default_wrist_occl.glb'
import default_env_map from './assets/neutral.hdr'

import { loadFile } from '../utils'

export function replacePhongMaterials (object) {
	object.traverse (function (child) {
		if (child.material && (
			(child.material.type === 'MeshPhongMaterial') ||
			(child.material[0] && (child.material[0].type === 'MeshPhongMaterial'))
		)) {
			child.material = new MeshStandardMaterial ();
		}
	});
	return object;
}


// most of this could prrobably be replaced with loading package.zip

export function initScene (scene, footL, footR, wrist, onComplete) {
  Cache.enabled = true;

	const manager = new LoadingManager ();
	manager.onLoad = function () {
		dispatcher.dispatchEvent ({ type: 'hide preloader', after: { type: 'default assets loaded' }});

		const loader = new FileLoader();
    loader.responseType = 'blob';
    loader.load(default_env_map, function (file) {
      file.name = 'envMap' + default_env_map.match(/\.[^\.]+$/)[0];
      loadFile(file, { type: 'environment map' });
    });
	};

	dispatcher.dispatchEvent ({ type: 'environment map', url: default_env_map, manager: manager });

	const gltfLoader = new GLTFLoader (manager); var leftShoe, rightShoe, ankleLeft, ankleRight, watch, arm, wristOccluder;
	const objLoader = new OBJLoader (manager); var ankle, leg, foot;
	
	// DRACO
	const dracoLoader = new DRACOLoader();
	dracoLoader.setDecoderPath( '/draco/gltf/');
	dracoLoader.setDecoderConfig({ type: 'js' });
	gltfLoader.setDRACOLoader(dracoLoader);

	var loaded = [0, 0, 0, 0, 0, 0, 0, 0], total = [3150212, 3155936, 1, 1, 1, 9358708, 7944, 48412];

	const reportProgress = function (index) {
		return function (event) {
			loaded[index] = event.loaded;
			// anything gzipped has zero event.total :(
			total[index] = total[index] || event.total;
			dispatcher.dispatchEvent ({ type: 'update preloader', progress:
				100 * loaded.reduce(function (a, v) { return a + v; }, 0) /
				       total.reduce(function (a, v) { return a + v; }, 0)
			});
		};
	};

	gltfLoader.load (default_shoe_left, function (result) {
		cacheFile ('model_l.glb', Cache.get (default_shoe_left));
		leftShoe = result.scene;
	}, reportProgress (0));

	gltfLoader.load (default_shoe_right, function (result) {
		cacheFile ('model_r.glb', Cache.get (default_shoe_right));
		rightShoe = result.scene;
	}, reportProgress (1));

	gltfLoader.load (default_ankle_left, function (result) {
		cacheFile ('ankle_occ_l.glb', Cache.get (default_ankle_left));
		ankleLeft = result.scene;
	}, reportProgress (2));

	gltfLoader.load (default_ankle_right, function (result) {
		cacheFile ('ankle_occ_r.glb', Cache.get (default_ankle_right));
		ankleRight = result.scene;
	}, reportProgress (3));

	objLoader.load (default_leg, function (result) {
		leg = replacePhongMaterials (result);
	}, reportProgress (4));

	objLoader.load (default_foot, function (result) {
		foot = replacePhongMaterials (result);
		// exclude these feet meshes from raycast
		result.traverse (function (mesh) {
			if (mesh.geometry) {
				mesh.raycast = function () {};
			}
		});
	}, reportProgress (5));

	gltfLoader.load (default_watch, function (result) {
		cacheFile ('model_w.glb', Cache.get (default_watch));
		watch = result.scene;
	}, reportProgress (6));

	gltfLoader.load (default_wrist_occluder, function (result) {
		cacheFile ('wrist_occ.glb', Cache.get (default_wrist_occluder));
		wristOccluder = result.scene;
	}, reportProgress (7));

	gltfLoader.load (default_wrist, function (result) {
		result.scene.updateMatrixWorld();
		const mesh = result.scene.getObjectByProperty('isMesh', true);

		// mesh -> result.scene transformation matrix
		const meshToScene = mesh.matrixWorld.clone().multiply(
			result.scene.matrixWorld.clone().invert()
		);

		mesh.name = '';
		mesh.geometry.applyMatrix4(meshToScene);
		mesh.matrix.identity().decompose(mesh.position, mesh.quaternion, mesh.scale);

		arm = new Group();		
		arm.position.copy(result.scene.position);
		arm.quaternion.copy(result.scene.quaternion);
		arm.scale.copy(result.scene.scale);

		arm.add(mesh);

		// exclude from raycast
		arm.traverse (function (mesh) {
			if (mesh.geometry) {
				mesh.raycast = function () {};
			}
		});
	}, reportProgress (7));


	const cloneObj = function (object) {
		const clone = object.clone (true);
		clone.traverse (function (mesh) {
			if (mesh.material) {
				mesh.material = mesh.material.clone ();
			}
		});
		return clone;
	};

	 dispatcher.once ('default assets loaded', function () {
 		foot.position.x = -0.15;
 		scene.add (foot);

 		const footCopy = xFlipObject3D (foot.clone (true));
 		scene.add (footCopy);

 		footCopy.add (footL);
 		foot.add (footR);

 		ankleRight.name = 'ankle_occ_r';
		ankleLeft.name = 'ankle_occ_l';

		footR.add (ankleRight);
		footL.add (ankleLeft);

 		leg.name = 'leg_occ_r';
		leg.rotation.x = -13 * Math.PI / 180;
		leg.scale.set (1.0, 0.698, 1.0);

 		
 		const legCopy = xFlipObject3D (cloneObj (leg)); legCopy.name = 'leg_occ_l';

		leg.position.set(-0.014, 0.035, -0.192);
		legCopy.position.set(0.016, 0.038, -0.192);

		footR.add (leg);
 		footL.add (legCopy);

 		leftShoe.name = 'glb';
 		rightShoe.name = 'glb';
 		footR.add (rightShoe);
 		// whats with the slowdown?
 		footL.add (leftShoe);

		foot.legVisible = true;
		footCopy.legVisible = false;

 		// create plane occluders
 		const planeGeometry = new PlaneBufferGeometry (0.2, 0.2);
 		const planeMaterial = new MeshStandardMaterial ({ side: DoubleSide });
 		const planeR = new Mesh (planeGeometry, planeMaterial);
 		
 		planeR.rotation.x = -69 * Math.PI / 180;
 		planeR.name = 'plane_occ_r';
 		

 		const planeL = planeR.clone ();
 		planeL.material = planeR.material.clone ();
 		planeL.name = 'plane_occ_l';

		planeR.position.set (-0.011, 0.127, -0.29);
		planeL.position.set (0.011, 0.127, -0.29);



		footR.add (planeR);
 		footL.add (planeL);


		scene.add (arm);

		arm.add (wrist);

		wristOccluder.name = 'wrist_occ';
		wrist.add (wristOccluder);

		watch.name = 'glb';
		wrist.add (watch);

 		onComplete ();

 	});
}
