import React, {Component} from 'react'
import dispatcher from '../../../dispatcher'
import './index.css'

import {Input, OpacityInput} from '../input';

class Transform extends Component {
	state = { leg : 'right' }

	setTransformProperties = (event) => {
		this.setState ({
			opacity  : event.opacity,
			position : event.position,
			rotation : event.rotation,
			scale    : event.scale
		});
	}

	setCurrentLeg = (event) => {
		this.setState ({
			leg : event.leg
		});
	}

	componentDidMount () {
		dispatcher.addEventListener ('transform properties', this.setTransformProperties);
		dispatcher.addEventListener ('leg visibility', this.setCurrentLeg);
	}

	componentWillUnmount () {
		dispatcher.removeEventListener ('transform properties', this.setTransformProperties);
		dispatcher.removeEventListener ('leg visibility', this.setCurrentLeg);
	}
	render () {
		return (
			<div className="transform" style={{ opacity: this.state.position ? 1 : 0.2 }}>
				<div className="label">Transform</div>
				<div className='transform-row'>
					<h3>Position</h3>
					<div className='transform-row-inputs'>
						<Input label="x" value={ this.state.position ? this.state.position.x : 0 } step="0.001" event="position" disabled={!this.state.position} />
						<Input label="y" value={ this.state.position ? this.state.position.y : 0 } step="0.001" event="position" disabled={!this.state.position} />
						<Input label="z" value={ this.state.position ? this.state.position.z : 0 } step="0.001" event="position" disabled={!this.state.position} />
					</div>
				</div>
				
				<div className='transform-row'>
					<h3>Rotation</h3>
					<div className='transform-row-inputs'>
						<Input label="x" value={ this.state.rotation ? this.state.rotation.x : 0 } step="1" event="rotation" disabled={!this.state.rotation} />
						<Input label="y" value={ this.state.rotation ? this.state.rotation.y : 0 } step="1" event="rotation" disabled={!this.state.rotation} />
						<Input label="z" value={ this.state.rotation ? this.state.rotation.z : 0 } step="1" event="rotation" disabled={!this.state.rotation} />
					</div>
				</div>
				<div className='transform-row'>
					<h3>Scale</h3>
					<div className='transform-row-inputs'>
						<Input label="x" value={ this.state.scale ? this.state.scale.x : 1 } step="0.001" event="scale" disabled={!this.state.scale} />
						<Input label="y" value={ this.state.scale ? this.state.scale.y : 1 } step="0.001" event="scale" disabled={!this.state.scale} />
						<Input label="z" value={ this.state.scale ? this.state.scale.z : 1 } step="0.001" event="scale" disabled={!this.state.scale} />
					</div>
				</div>

				<div className='opacity-row'>
					<div className="label">Opacity</div>
					<OpacityInput value={ this.state.opacity !== undefined ? this.state.opacity : 100 } disabled={this.state.scale === undefined} />
				</div>

				<button className="button" style={{ display: (this.state.leg === 'both') ? '' : 'none' }} onClick={() => dispatcher.dispatchEvent({ type: 'copy transform to other foot' }) }>Copy to other foot</button>
			</div>
		)
	}
}

export default Transform
