import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import "./index.css";
import { setAPIUserNameAndKey, getAPIUserNameAndKey } from '../../../utils';

const ModalApiKeys = () => {
  const [show, setShow] = useState(false);
  const [username, setUsername] = useState('');
  const [key, setKey] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = () => {
    console.log('submit');
    setAPIUserNameAndKey(username, key);
    setShow(false);
  }

  useEffect(() => {
    if (!show) return;
    console.log('useEffect');
    getAPIUserNameAndKey().then(api => {
      setUsername(api.username);
      setKey(api.key);
		}, () => {});
  }, [show]);


  return (<>
    <button className="button button-api-keys button-right-angle" onClick={handleShow}>API Keys</button>
    <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title>Enter your credentials</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='api-inputs'>
          <div className="api-input" ><input placeholder="Username" defaultValue={username} onInput={(e) => setUsername(e.target.value) } /><span></span></div>
          <div className="api-input" ><input className="api-input" placeholder="API key" defaultValue={key} onInput={(e) => setKey(e.target.value)} /><span></span></div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="button" onClick={handleSubmit}>
          Submit
        </button>
      </Modal.Footer>
    </Modal>
    </>
  );
};
export default ModalApiKeys;
