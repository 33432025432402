import React, { useState, useEffect, useRef } from 'react';
import './index.css';

const Tabs = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].id);
  const [activeTabWidth, setActiveTabWidth] = useState(0);
  const [activeTabLeft, setActiveTabLeft] = useState(0);
  const tabHeaderRef = useRef(null);

  const handleTabClick = (tabId, index, event) => {
    setActiveTab(tabId);
    setActiveTabWidth(event.currentTarget.offsetWidth);
    setActiveTabLeft(event.currentTarget.offsetLeft);
  };

  useEffect(() => {
    const firstTab = tabHeaderRef.current.querySelector('.tab-item');
    if (firstTab) {
      setActiveTabWidth(firstTab.offsetWidth);
      setActiveTabLeft(firstTab.offsetLeft);
    }
  }, []);

  return (
    <div className="tabs">
      <div className="tab-header" ref={tabHeaderRef}>
        {tabs.map((tab, index) => (
          <div
            key={tab.id}
            className={`tab-item ${activeTab === tab.id ? 'active' : ''}`}
            onClick={(event) => handleTabClick(tab.id, index, event)}
          >
            {tab.title}
          </div>
        ))}
      </div>
      <div
        className="underline"
        style={{
          transform: `translateX(${activeTabLeft}px)`,
          width: `${activeTabWidth}px`,
        }}
      ></div>
      <div className="tab-content">
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={`tab-pane ${activeTab === tab.id ? 'active' : ''}`}
          >
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
