import React, {Component} from 'react'
import dispatcher from '../../../dispatcher'
import {loadFile} from '../../../utils'
import './index.css'

class Model extends Component {
  state = {
    name: 'model_r.glb',
    size: 5,
    tris: 49998,
  };

  setModelName = (event) => {
    setTimeout(
      () => {
        const packageElements = JSON.parse(
          window.sessionStorage.getItem('packageElements') || '{}'
        );
        const model = packageElements[event.name + '.glb'];
        this.setState({
          name: event.name + '.glb',
          size: model && model.size ? model.size : 5,
          tris: model && model.tris ? model.tris : 49998,
        });
      },
      event.type === 'selected' ? 50 : 500
    ); // wait for the model to load and tris to be calculated
  };

  componentDidMount() {
    dispatcher.addEventListener('selected', this.setModelName);
    dispatcher.addEventListener('leg visibility', this.setModelName);
  }

  componentWillUnmount() {
    dispatcher.removeEventListener('selected', this.setModelName);
    dispatcher.removeEventListener('leg visibility', this.setModelName);
  }

  render() {
    return (
      <div className="model">
        <div className="model-header">
          <div className="model-label">Model</div>
          <div className="button file">
            <i className="model-upload-icon"></i>
            <input
              type="file"
              accept=".drc,.glb,.gltf,.obj"
              onChange={(event) => {
                console.log('egg')
                console.log(event.nativeEvent.target.files[0])
                loadFile(event.nativeEvent.target.files[0], {
                  type: 'import model',
                  model: this.state.name,
                });
                event.target.value = '';
              }}
            ></input>
          </div>
        </div>
        <div className="name">{this.state.name}</div>
        <div className="name">
          Size:{' '}
          <span className="value">
            {this.state.size < 1
              ? this.state.size.toFixed(3)
              : Math.round(this.state.size)}
            MB
          </span>
        </div>
        <div className="name">
          Polygon count:{' '}
          <span className="value">{this.state.tris.toLocaleString()}</span>
        </div>
      </div>
    );
  }
}

export default Model
