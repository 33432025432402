import React, { useCallback, useState } from 'react';
import Dropdown from 'react-dropdown';
import './index.css';
import ImageExposureAdjustment from '../imageexposure';
import default_env_map from '../../../scene3d/assets/neutral.hdr';
import vyking_env_map from '../../../scene3d/assets/vyking_hdr.hdr';
import dispatcher from '../../../dispatcher';
import { FileLoader } from 'three';
import { loadFile } from '../../../utils';

const images = {
  default_env_map,
  vyking_env_map,
};

const lightingOptions = [
  { value: 'default_env_map', label: 'Default' },
  { value: 'vyking_env_map', label: 'Vyking' },
];

const canvas = document.createElement('canvas');
let onCanvasUpdate;
dispatcher.addEventListener('environment map loaded', function (event) {
  canvas.width = event.image.width;
  canvas.height = event.image.height;
  if (event.image.data) {
    const from = event.image.data,
      to = new Uint8ClampedArray(from.length);
    for (let i = 0; i < from.length; i += 4) {
      // https://theswissbay.ch/pdf/Gentoomen%20Library/Game%20Development/Programming/Graphics%20Gems%202.pdf#page=112
      if (from[i + 3] > 0) {
        const v = 42 * 2 ** (from[i + 3] - 128);
        to[i + 0] = Math.sqrt(from[i + 0] * v);
        to[i + 1] = Math.sqrt(from[i + 1] * v);
        to[i + 2] = Math.sqrt(from[i + 2] * v);
      }
      to[i + 3] = 255;
    }
    canvas
      .getContext('2d')
      .putImageData(
        new ImageData(to, event.image.width, event.image.height),
        0,
        0
      );
  } else {
    canvas.getContext('2d').drawImage(event.image, 0, 0);
  }

  if (onCanvasUpdate) {
    onCanvasUpdate(canvas.toDataURL());
  }
});

const Lighting = () => {
  const [selectedOption, setSelectedOption] = useState(lightingOptions[0]);
  const previewImage = useCallback(function (image) {
    if (image) {
      onCanvasUpdate = (url) => {
        image.src = url;
      };
    } else {
      onCanvasUpdate = undefined;
    }
  });

  const handleImageUpdate = (imageData) => {
    const loader = new FileLoader();
    loader.responseType = 'blob';
    loader.load(imageData, function (file) {
      file.name = 'envMap' + imageData.match(/\.[^\.]+$/)[0];
      loadFile(file, { type: 'environment map' });
    });
    // clear image input
    document.getElementById('file-input').value = null;
  };

  const handleImageUpload = (event) => {
    if (!event?.nativeEvent?.target?.files[0]) {
      return;
    }
    loadFile(event.nativeEvent.target.files[0], { type: 'environment map' });
    event.target.value = '';
    setSelectedOption(null);
  };

  return (
    <div className="lighting">
      <div className="label">Environment map</div>
      <div className="dropdown-section">
        <Dropdown
          className="lighting-dropdown"
          options={lightingOptions}
          value={selectedOption}
          onChange={function (option) {
            handleImageUpdate(images[option.value]);
          }}
        />
        <label htmlFor="file-input" className="button button-upload"></label>
      </div>
      <input
        className="file-input"
        id="file-input"
        type="file"
        accept=".hdr,.jpe,.jpeg,.jpg"
        onChange={handleImageUpload}
      ></input>
      <img
        ref={previewImage}
        alt="Selected env map"
        className="preview-image"
      />
      <ImageExposureAdjustment />
    </div>
  );
};

export default Lighting;
